<template>
  <KTPortlet
    :title="(thisLayoutConfig || {}).title"
    adBodyStyle="padding: 0 !important;"
  >
    <template slot="body">
      <iframe
        v-if="thisLayoutConfig.meta.type === 'iframe'"
        frameBorder="0"
        :src="thisLayoutConfig.meta.url"
        :style="`min-height: ${thisLayoutConfig.meta.height || '1024px'};`"
      ></iframe>
    </template>
  </KTPortlet>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {
    KTPortlet
  },
  data() {
    return {
      //thisLayoutConfig: null
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    thisLayoutConfig() {
      return this.layoutConfig().customMenu.aside[this.$route.params.pathId] || null;
    }
  },
  beforeDestroy() {},
  mounted() {
    /*this.$data.thisLayoutConfig = this.layoutConfig().customMenu.aside[
      this.$route.params.pathId
    ];*/
    if (
      this.thisLayoutConfig !== undefined &&
      this.thisLayoutConfig.meta !== undefined
    ) {
      if (this.thisLayoutConfig.meta.type === "redirect") {
        window.location.href = this.thisLayoutConfig.meta.url;
      }
      if (this.thisLayoutConfig.meta.breadcrumbs !== undefined) {
        this.$store.dispatch(
          SET_BREADCRUMB,
          this.$data.thisLayoutConfig.meta.breadcrumbs.map(x => {
            return {
              title: x
            };
          })
        );
      } else {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "My Account" },
          { title: "Custom" }
        ]);
      }
    }
  },
  methods: {}
};
</script>
